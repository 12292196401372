import { ParametersMap } from '@/utilities/formula.constants';
import { ITEM_DATA_STATUS } from '@/trendData/trendData.constants';

export const SCATTER_PLOT_MODES = {
  DISPLAY_RANGE: 'DISPLAY_RANGE',
  CAPSULES: 'CAPSULES',
};
export const SCATTER_PLOT_VIEWS = {
  SCATTER_PLOT: 'SCATTER_PLOT',
  DENSITY_PLOT: 'HEAT_MAP',
};
export const SCATTER_PLOT_ID = 'scatterPlotChart';
export const SCATTER_PLOT_COLORS = {
  LOW: 'rgba(132, 132, 130, 0.7)',
  MID: 'rgba(70, 73, 250, 0.7)',
  HIGH: 'rgba(243, 132, 0, 0.7)',
};
export const SCATTER_PLOT_OPACITY = 0.7;
export const SCATTER_PLOT_REDUCED_OPACITY = 0.3;
export const MINIMAP_OPACITY = 0.5;
export const DENSITY_PLOT_ID = 'heatMapChart';

export interface ScatterPlotSignal {
  id: string;
  color?: string;
  dataStatus?: ITEM_DATA_STATUS;
}

export interface SeeqScatterPlotPoint extends Highcharts.Point {
  time: number;
}

interface BaseCapsulePropertyColorsConfig {
  isStringProperty: boolean;
  propertyIndex: number;
  transformValue: (rawValue: number | string) => number | string;
}

export interface StringCapsulePropertyColorsConfig extends BaseCapsulePropertyColorsConfig {
  valueColorMap: Record<string, string>;
}

export interface NumericCapsulePropertyColorsConfig extends BaseCapsulePropertyColorsConfig {
  colors: any;
  minColor: string;
  maxColor: string;
  minValue: number;
  maxValue: number;
  diffValue: number;
}

export interface Extremes {
  min: number | null;
  max: number | null;
}

export type SignalId = string;
export type XYPlotRegion = {
  x: Extremes;
  ys: Record<SignalId, Extremes>;
  id?: string;
  dateTime?: string;
};
export const DEFAULT_EXTREMES = {
  min: null,
  max: null,
};
export const EMPTY_XY_REGION: XYPlotRegion = {
  x: DEFAULT_EXTREMES,
  ys: {},
};

export interface Range {
  start: number;
  end: number;
}

export interface ScatterPlotFormula {
  parameters: ParametersMap;
  xyTableFormula: string;
  sampleLimitFormula?: string;
  cancellationGroup: string;
}

export interface FxLineMetadata {
  formula: string;
  rSquared?: number;
}

export interface ScatterPlotColorRange {
  id: string;
  color: string;
  range: { startTime: number; endTime: number };
}

export type CapsulePropertyColorsConfig = StringCapsulePropertyColorsConfig | NumericCapsulePropertyColorsConfig;
export const MAX_MARKER_SIZE_CALCULATED = 3;
export const MIN_MARKER_SIZE_CALCULATED = 1;
export const MAX_MARKER_SIZE_CUSTOM = 5;
export const MIN_MARKER_SIZE_CUSTOM = 0.5;
// This value was determined via trial and error, and seems to be the maximum number of points we can
// display without enabling boost mode, without the plot and browser lagging significantly.
export const MIN_SAMPLES_FOR_BOOST = 8000;
export const MAX_CAPSULE_SCATTER_PLOT_SAMPLES = 1000000; // Too many can cause high CPU usage and diminishing returns
export const FX_LINE_SAMPLE_SPACING = 5; // Number of pixels between each sample of a function of x line
